@tailwind base;
@layer base {
  @font-face {
    font-family: 'Game of Squids';
    font-style: normal;
    font-weight: normal;
    src: local('Game of Squids Regular'), url(./fonts/Game Of Squids.ttf) format('truetype');
  }
  @font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: bold;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'DMSans';
    src: url('./fonts/DMSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
}
