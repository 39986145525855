@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts.css';

:root {
  /*********************************************************************************
    BASE COLORS 
    Base colors are used to describe specific colors. They are grouped according to
    basic color theory.
    
    Prefixes as follows:
    --base-clr: Comes from brand book and any direct derivative weights.
    --analog-clr: Analogous colors.
    --comp-clr: Complementary colors.
    --tri-clr: The opposing two colors (relative to the base) on a triadic scale.

    Colors hex values were generated using these tools:
    - https://material.io/design/color/the-color-system.html#tools-for-picking-colors
    and 
    - https://material.io/resources/color
  *********************************************************************************/

  /* BLACK - 000000 is the root color */

  --base-clr-black-dark: #000000;

  /* GREY - ddd7de is the root color */
  --base-clr-grey-light: #f5f4ee;
  --base-clr-grey-primary: #ddd7de; /* Roulette Color (really, a very light purple) */
  --base-clr-grey-dark: #9b9a95; /* Roulette Color (from the triadic to be more grey-like)*/
  --base-clr-grey-drawer: #dad5dc;

  /* GOLD - #f8a430 is the root color */
  --base-clr-gold-light: #ffe1be; /* Roulette Color */
  --base-clr-gold-primary: #f8a430; /* Roulette Color */
  --base-clr-gold-dark: #4c3900;
  --base-clr-gold-1100: #4f310d;
  --base-clr-gold-1000: #f8a430;
  --base-clr-gold-900: #f3a62b;
  --base-clr-gold-800: #9d872e;
  --base-clr-gold-700: #b19c33;
  --base-clr-gold-400: #ffd1a0;

  /* skipping colors on purpose */
  --base-clr-gold-300: #e1d372;
  --base-clr-gold-200: #ffe1be;

  /* LIGHT GOLD - #ffca70 is the root color */
  --base-clr-lt-gold-light: #f8d53b;
  --base-clr-lt_gold-primary: #f3a62b;
  --base-clr-lt_gold-dark: #ee7f1d;

  /* BROWN - #664e50 is the root color */
  --base-clr-brown-light: #916620;
  --base-clr-brown-primary: #664e50;
  --base-clr-brown-dark: #463739;

  /**************************************
    NAMED COLORS (for normal .css files)
    Use the above BASE COLORS to map to
    semantic color names here.
  ***************************************/

  /*********************************************************************************
    BASE NUMBERS 
  *********************************************************************************/
  --header-height: 4rem;
  --footer-height: calc(60vh);
  --footer-top: 60%;
  --padding-main: 1.5rem;
  --betInfo-top: 28%;

  /*********************************************************************************
    Spinning Variables
  *********************************************************************************/
  --wheel-size-default: 1000px;
  --wheel-color: #e99501;
  --neutral-color: #4c2900;
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 11;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;

  --toastify-toast-width: auto;
}

.btn,
.table :where(thead, tfoot) :where(th, td) {
  @apply normal-case;
}
.door {
  @apply gap-x-4 h-full pt-[344px] flex;
}
.door > img.inner {
  @apply invisible innerDoor:visible;
}
.door > img.outer {
  @apply invisible outerDoor:visible;
}

html,
body {
  background-image: url('/public/images/backgrounds/background.jpg');
  background-color: var(--base-clr-grey-primary);
  color: var(--base-clr-black-dark);
  height: 100%;
  font-family: 'DMSans', system-ui, sans-serif;
}
header {
  background-color: var(--base-clr-gold-dark);
  color: var(--base-clr-black-dark);
}
.modal-color {
  background-color: var(--base-clr-grey-300);
  color: var(--base-clr-black-dark);
}

.place-a-bet > button,
.place-a-bet_small > button {
  background: linear-gradient(0deg, #52380a, #52380a), #664e50;
  box-shadow: inset 0px -5px 0px #3d2503;
  border-radius: 214.267px;
}

.place-a-bet,
.connect-a-wallet {
  background: linear-gradient(180deg, rgba(117, 80, 8, 0) 0%, rgba(82, 57, 7, 0.2) 100%), #ffca70;
  box-shadow: 0px 84px 127px rgba(117, 66, 10, 0.44), 0px 35.0932px 53.0576px rgba(117, 66, 10, 0.316296),
    0px 18.7625px 28.3671px rgba(117, 66, 10, 0.262287), 0px 10.5181px 15.9024px rgba(117, 66, 10, 0.22),
    0px 5.58609px 8.44563px rgba(117, 66, 10, 0.177713), 0px 2.3245px 3.51442px rgba(117, 66, 10, 0.123704);
  border-radius: 214.267px;
}
.you-win {
  background-attachment: fixed;
  background-position: center;
  background-image: url('../public/images/backgrounds/congratsModal.png');
  box-shadow: 0px 84px 127px rgba(117, 66, 10, 0.44), 0px 35.0932px 53.0576px rgba(117, 66, 10, 0.316296),
    0px 18.7625px 28.3671px rgba(117, 66, 10, 0.262287), 0px 10.5181px 15.9024px rgba(117, 66, 10, 0.22),
    0px 5.58609px 8.44563px rgba(117, 66, 10, 0.177713), 0px 2.3245px 3.51442px rgba(117, 66, 10, 0.123704);
}
.winning-amount {
  width: 453.18px;
  height: 103.74px;
  left: 492.91px;
  top: 229.44px;

  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 105px;
  text-align: center;
  letter-spacing: 2.26218px;

  color: #693e07;
}
.win {
  left: 48.68%;
  right: 48.76%;
  top: 23.63%;
  bottom: 74.16%;

  font-style: normal;
  font-weight: 500;
  font-size: 20.0426px;
  line-height: 32px;
  /* or 158% */

  text-align: center;
  letter-spacing: -0.025em;

  color: #000000;
}
.connect-a-wallet {
  border-radius: none;
}

.toast-message-container-success {
  border-radius: 30px;
  border: 2px solid;
  border-color: #07bc0c;
}
.toast-message-container-failure {
  border-radius: 30px;
  border: 2px solid;
  border-color: #e74c3c;
}
.connect-wallet,
.claim-all {
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%),
    radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #faa833;
  background-blend-mode: overlay, overlay, normal;
  box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(55px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 90px !important;
}

.noHover {
  pointer-events: none;
}

.total-bets {
  background: rgb(255, 221, 173, 55%);
  box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
