.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: var(--wheel-size-default);
  height: var(--wheel-size-default);
  background-repeat: no-repeat;
  background-size: 100%;
  margin: auto;
  user-select: none;
}

.wheel-container::before,
.wheel-container::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  z-index: 2;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size-default);
  height: var(--wheel-size-default);
  overflow: hidden;
  border-radius: 50%;
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
  cursor: pointer;
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1)) - 90deg));
}

.wheel::after {
  display: block;
  position: absolute;
  content: '';
  background-color: var(--neutral-color);
  width: 60%;
  height: 60%;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  color: var(--neutral-color);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
}

.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.1;
  --slice-max-width: calc(var(--PI) * var(--wheel-size-default) + var(--wheel-size-default) / 2);
  --slice-width: calc((var(--slice-max-width) / var(--nb-item)));
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  border-right: solid var(--neutral-color) calc(var(--wheel-size-default) / 2);
}

.wheel-item-icon {
  position: absolute;
  top: calc(100% - 50px);
  right: calc(var(--wheel-size-default) / 20);
  max-width: 100px;
}

.wheel-arrow {
  position: absolute;
  top: calc(var(--wheel-size-default) / 5.7);
  left: 49%;
  width: 60px;
  z-index: 1;
  transform: translate(-35%, 0) scale(75%);
}

.bet-info-wrapper {
  position: absolute;
  top: calc(63% - var(--wheel-size-default));
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

.wheel-container,
.wheel {
  width: var(--wheel-size-default);
  height: var(--wheel-size-default);
}
